<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
      </van-sticky>
      <div class="detail">
        <div style=""  v-if="articles.summary.indexOf('生日') >= 0">
          <div
            style="display: table-cell; vertical-align: middle"
           
          >
            <img src="/image/birthday cake.png" style="max-width: 40px" />
          </div>

          <div style="display: table-cell; vertical-align: middle">
            <h2 style="text-align: center">
              {{ articles.summary }}
            </h2>
          </div>
        </div>
          <h2 style="text-align: center"  v-if="articles.summary.indexOf('生日') < 0">
              {{ articles.summary }}
            </h2>

        <div class="acontent" v-html="articles.detailsHtml"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSystemMessageInfo } from "@/api/systemmessage";

export default {
  name: "SystemMessageDetail",
  data() {
    return {
      loading: true,

      title: "系统通知",
      articles: {},
    };
  },
  components: {},
  updated() {
    this.addpreview();
  },
  created() {
    this.initData();
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/user/systemmessagedetail") {
        if (to.query != from.query) {
          this.loading = true;
          this.initData();
          this.onLoad();
        }
      }
    },
  },
  mounted() {},

  methods: {
    initData() {},

    async onLoad() {
      let para = this.$route.query.para;
      let aresult = await getSystemMessageInfo(para); //({ ...this.page })
      console.log(aresult);
      this.articles = aresult.data.data;

      this.loading = false;
    },
    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview", this.articles.id);
        });
      });
      this.$previewRefresh();
    },
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.detail {
  padding: 10px 10px 20px;
}

.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
  /deep/  p {
    padding: revert;
    margin: revert;
}

}
</style>